// @flow strict
import React from "react"
import Layout from "../components/Layout"
import Sidebar from "../components/Sidebar"
import Page from "../components/Page"
import { useSiteMetadata } from "../hooks"
import type { PageContext, AllMarkdownRemark } from "../types"

type Props = {
  data: AllMarkdownRemark,
  pageContext: PageContext,
}

const IndexTemplate = ({ data, pageContext }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata()

  const { currentPage, hasNextPage, hasPrevPage, prevPagePath, nextPagePath } = pageContext

  const pageTitle = siteTitle

  // data
  const services = [
    {
      text: "Airsonic",
      description: "Ecouter et gérez des collections de musique",
      link: "https://music.mrflos.pw",
    },
    {
      text: "Bitwarden",
      description: "Gérez les mots de passe et autres informations sensibles",
      link: "https://password.mrflos.pw",
    },
    {
      text: "CryptPad",
      description: "Éditeur chiffré collaboratif en temps réel.",
      link: "https://cryptpad.mrflos.pw",
    },
    {
      text: "Diagrams.net",
      description: "Application en ligne qui permet de faire des schémas et du dessin vectoriel.",
      link: "https://diagrams.mrflos.pw",
    },
    {
      text: "Galene",
      description: "Serveur de visioconférence minimaliste et rapide",
      link: "https://visio.mrflos.pw",
    },
    {
      text: "GitLab",
      description: "Gestionnaire de dépôts Git",
      link: "https://gitlab.mrflos.pw",
    },
    {
      text: "Grafana",
      description: "Tableaux de bords de supervision",
      link: "https://graph.mrflos.pw",
    },
    {
      text: "HedgeDoc",
      description: "Éditeur collaboratif pour travailler sur des notes en Markdown",
      link: "https://md.mrflos.pw",
    },
    {
      text: "Mindmaps",
      description: "Outil de création de cartes mentales",
      link: "https://mindmap.mrflos.pw",
    },
    {
      text: "Mumble Server",
      description: "Logiciel libre de voix sur IP",
      link: "https://audioconf.mrflos.pw",
    },
    {
      text: "Nextcloud",
      description: "Stockage en ligne, plateforme de partage de fichiers et diverses autres applications",
      link: "https://cloud.mrflos.pw",
    },
    {
      text: "PeerTube",
      description:
        "Plateforme de diffusion vidéo, connectée à un réseau fédéralisé",
      link: "https://video.mrflos.pw",
    },
    {
      text: "Question2Answer",
      description: "Forum de type plateforme de Question/Réponses",
      link: "https://mrflos.pw/forum",
    },
    {
      text: "Tiny Tiny RSS",
      description: "Lecteur de flux d’actualité utilisant les protocoles RSS et Atom",
      link: "https://rss.mrflos.pw",
    },
    {
      text: "YesWiki ",
      description: "Un wiki facilitant la coopération, rapide à prendre en main",
      link: "https://wiki.mrflos.pw",
    },
    {
      text: "Zerobin",
      description: "Un pastebin minimaliste, où le serveur n'a aucune connaissance des données copiées",
      link: "https://paste.mrflos.pw",
    },
  ]
  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Sidebar isIndex />
      <Page title="Mes services libres auto-hébergés">
        {services.map((service) => (
          <li key={service.link} style={{listStyle: 'none',
            marginBottom: '2rem'}}>
            <a href={`${service.link}`}>{service.text}</a>
            <div>{service.description}</div>
          </li>
        ))}
      </Page>
    </Layout>
  )
}

export default IndexTemplate
